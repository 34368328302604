html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  margin: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block; }

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

a {
  background: transparent; }

a:active, a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b, strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: .67em 0; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code, kbd, pre, samp {
  font-family: monospace,monospace;
  font-size: 1em; }

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button, select {
  text-transform: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: none;
  cursor: pointer; }

button[disabled], html input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: none;
  padding: 0;
  margin: 0; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td, th {
  padding: 0; }

@keyframes hippyshake {
  0% {
    transform: translateX(2px); }
  20% {
    transform: translateX(0); }
  40% {
    transform: translateX(-2px); }
  60% {
    transform: translateX(0); }
  80% {
    transform: translateX(2px); }
  100% {
    transform: translateX(0); } }

@keyframes loading {
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

.hidden {
  display: none; }

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .loading p {
    color: #fff; }
  .loading .dot {
    width: 14px;
    height: 14px;
    margin: 0 7px;
    background: #fff; }
  .loading p {
    margin: 0;
    text-align: center; }
  .loading .dot {
    display: inline-block;
    transform: scale(0);
    border-radius: 50%;
    animation: loading 1s ease infinite; }
    .loading .dot:nth-child(2) {
      animation: loading 1s ease infinite 0.3s; }
    .loading .dot:nth-child(3) {
      animation: loading 1s ease infinite 0.6s; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.button {
  display: inline-block;
  min-height: 50px;
  padding: 0.75rem 2.25rem;
  border: 1px solid transparent;
  border-radius: 100px;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  transition: all 0.2s ease-out; }

.button.small {
  min-height: 37.5px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.7rem; }

.button.large {
  min-height: 62.5px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 1.1rem; }

.button.fill-brand {
  background: #0c98a5;
  color: #fff; }

.button.fill-alt {
  background: #EC543C;
  color: #fff; }

.button.fill-tone {
  background: #fff;
  color: #EC543C; }

.button.to-fill-brand:hover {
  background: #0c98a5;
  color: #fff; }

.button.to-fill-alt:hover {
  background: #EC543C;
  color: #fff; }

.button.to-fill-tone:hover {
  background: #fff;
  color: #0c98a5; }

.button.outline-brand {
  border-color: #0c98a5;
  color: #0c98a5; }

.button.outline-alt {
  border-color: #EC543C;
  color: #EC543C; }

.button.outline-tone {
  border-color: #fff;
  color: #fff; }

.button.to-outline-brand:hover {
  border-color: #0c98a5;
  color: #0c98a5; }

.button.to-outline-alt:hover {
  border-color: #EC543C;
  color: #EC543C; }

.button.to-outline-tone:hover {
  border-color: #fff;
  color: #fff; }

.button a {
  color: inherit;
  transition: inherit; }

.button-arrow {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: .5rem;
  color: #111;
  font-weight: 700; }
  .button-arrow:hover {
    color: #0c98a5; }
  .button-arrow:after {
    content: '';
    display: block;
    width: 30px;
    height: 31px;
    background: url("/images/icons/orange-arrow.svg") no-repeat center center;
    background-size: contain; }
